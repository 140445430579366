.BackDrop {
    background-image: url("../../../assets/background/top-banner-1.jpg");
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 150px;
    transition: all 1s;
}

@media only screen and (max-width: 700px) {
    .BackDrop {
        height: 125px;
    }
}


.Photo {
    background-image: url("@assets/background/school-logo-1-header.jpg");
    background-size: cover;
    height: 200px;
    background-position-x: center;
    background-position-y: top;
}

.Body {
    text-align: left;
    justify-content: left;
    line-height: 2.5;
    padding-top: 32px;
    padding-bottom: 200px;
    transition: all 0.7s;
    background-color: white;
    z-index: 2;
    background:url("../../../assets/background/school-bottom-1.jpg") no-repeat bottom left;
    background-size: auto 40rem;
}

.CardTitle1 {
    align-items: center;
    color: rgb(0, 131, 192);
    display: flex;
    font-family: 'TaipeiSans';
    font-size: 25px;
    justify-content: left;
    margin-bottom: 30px;
}

.Icon1{
    color: rgb(0, 131, 192);
    margin-right: 80px;
}
.Card {
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
}

.Card1 {
    padding-left: 30px;
}

.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;  
    font-size: 30px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
    margin-bottom: 30px;
}

.Paragraph {
    text-indent: 50px;
    text-align: justify;
    display: inline;
}

.Content {
    margin-left: 40px;
}

.list2 li{
    padding-left: 18px; 
}

.list2 span {
    color: var(--gold-theme-color);
    font-weight: 700;
}

.Col{
    margin-bottom: 30px;
    padding: 0;
}

@media only screen and (min-width: 992px) {
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (max-width: 991px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        text-align: center;
        padding: 0;
    }

    .Circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        padding: none;
    }

    .CardTitle1 + p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 500px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        font-size: 25px;
        margin-bottom: 15px;
    }

    .Icon1 {
        padding: 0;
    }

    .Card1 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .Content {
        margin-left: 0px;
    }


    .CardTitle1 {
        font-size: 23px;
    }
}

@media only screen and (max-width: 360px) {
    .Body{
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .Card1 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .Content {
        margin-left: 0px;
    }
}


