/* 
    Carousel
    Carousel Mobile 
*/

.carousel-indicators [data-bs-target] {
    border-radius: 50%;
    height: 12px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    width: 12px;
}

@media only screen and (min-width: 601px) {
    .Carousell{
        margin: auto;
        width: 80px;
    }
}

@media only screen and (min-width: 600px) {
    .Carousell{
        margin: auto;
        width: 100%;
    }
}

/* 
    NavBar 
*/

.btn:focus{
    border: none;
    box-shadow: none;
    background-color: var(--light-theme-color);
    color: var(--dark-theme-color);
    transition: none;
}

.button:active, .btn:active, .btn-primary:active:focus{
    background-color: var(--light-theme-color);
    color: var(--dark-theme-color);
    outline: none;
    box-shadow: none;
    border:none;
}

/* 
    Sidebar 
*/


.modal-backdrop {
    background-color: var(--light-theme-color);
}
