@media only screen and (min-width: 992px){
    .Margin{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1450px){
    .Margin{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (min-width: 1550px){
    .Margin{
        padding-left: 18rem;
        padding-right: 18rem;
    }
}

@media only screen and (max-width: 991px){
    .Margin{
        padding-left: 2rem;
        padding-right: 2rem;
    }    
}

@media only screen and (max-width: 765px){

}

@media only screen and (max-width: 360px){
    .Margin{
        padding: 20px;
    }    
}