.Pages{
    z-index: -100;
    background-image: none;
    background-color: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Photo {
    background-image: url("@assets/background/school-logo-1-header.jpg");
    background-size: cover;
    height: 200px;
    background-position-x: center;
    background-position-y: top;
}