.Link, .Sublink {
    color: var(--dark-theme-color);
    text-decoration: none;
}

.Link:hover {
    color: var(--gold-theme-color);
}

.Sublink:hover {
    color: var(--dark-theme-color);
}

.NavItems {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    list-style-type: none;
    margin: 0;
    padding: 0;
}


.NavItem {
    color: var(--dark-theme-color);
    display: flex;
    justify-content: flex-start;
    line-height: 1.5;
    transition: all 0.2s;
}

.NavItem:hover {
    color: var(--gold-theme-color);
    cursor: pointer;
}

.NavButton {
    background-color: var(--gold-theme-color);
    border: none;
    color: var(--dark-theme-color);
    font-size: 14px;
    font-weight: 700;
    height: 50px;
    margin-bottom: 3px;
    margin-left: 15px;
    width: 100px;
}

.NavButton:hover {
    background-color: var(--light-theme-color);
    color: var(--dark-theme-color);
    cursor: pointer;
}

.Dropdown:hover .DropdownList {
    display: block;
}

.DropdownList {
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    border-top: 6px solid var(--gold-theme-color);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
    color: var(--dark-theme-color);
    display: none;
    list-style-type: none;
    margin-right: -20rem;
    padding: 8px 0;
    position: absolute;
    transition: all 1s;
}

.DropItem {
    align-items: center;
    display: flex;
    padding: 0.75rem 4rem 0.75rem 1rem;
    position: relative;
}

.DropItem:hover {
    background-color: var(--light-theme-color);
    cursor: pointer;
}

.Icon {
    margin-left: 3px;
}

.Portal {
    background-color: var(--dark-theme-color);
    color: var(--gold-theme-color);
    color: white;
}