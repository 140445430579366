/*===== VARIABLES CSS =====*/
:root{
  /*===== Colores =====*/
  --input-color: #80868B;
  --border-color: #DADCE0;

  /*===== Fuente y tipografia =====*/
  --body-font: 'Noto Sans HK', 'Noto Sans TC', 'Noto Serif TC', 微軟正黑體, sans-serif;
  --normal-font-size: 1rem;
  --small-font-size: .75rem;
}
  
/*===== BASE =====*/
*,::before,::after{
  box-sizing: border-box;
}

/* body{
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-image: url("../../assets/images/portal-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */

h1{
  margin: 0;
}

/*===== FORM =====*/
.l-form{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.form{
  width: 450px;
  padding: 4rem 3rem;
  border-radius: 1rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 10px 25px rgba(92,99,105,.2);
  background-color: white;
}

.form__title{
  font-weight: 600;
  margin-bottom: 3rem;
  color: var(--dark-theme-color);
}

.form__div{
  position: relative;
  height: 48px;
  margin-bottom: 1.5rem;
}
.form__input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: var(--normal-font-size);
  border: 1px solid var(--border-color);
  border-radius: .5rem;
  outline: none;
  padding: 1rem;
  background: none;
  z-index: 1;
}
.form__label{
  position: absolute;
  left: 1rem;
  top: 0.75rem;
  padding: 0 .25rem;
  font-weight: 300;
  background-color: #fff;
  color: var(--input-color);
  font-size: var(--normal-font-size);
  transition: .3s;
}
.form__button{
  display: block;
  margin-left: auto;
  margin-top: 1.75rem;
  padding: .75rem 2rem;
  outline: none;
  border: none;
  background-color: var(--gold-theme-color);
  color: var(--dark-theme-color);
  font-weight: 600;
  font-size: var(--normal-font-size);
  border-radius: .5rem;
  cursor: pointer;
  transition: .3s;
}
.form__button:hover{
  background-color: var(--light-theme-color);
}

/*Input focus move up label*/
.form__input:focus + .form__label{
  top: -.5rem;
  left: .8rem;
  color: var(--light-theme-color);
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus)+ .form__label{
  top: -.5rem;
  left: .8rem;
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus*/
.form__input:focus{
  border: 2px solid var(--light-theme-color);
}

@media only screen and (max-width: 350px){
    .form{
        padding: 4rem 2rem;
    }

    .form__button {
        margin-right: auto;
    }

    .form__title{
        text-align: center;
    }
}