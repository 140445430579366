.Carousel {
    display: block;
}

@media only screen and (max-width: 720px) {
    .Carousel {
        display: none;
    }
}

