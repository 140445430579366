.DropItems {
    padding: 0;

    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.NavItem, .DropItem {
    color: var(--dark-theme-color);
    /* font-weight: 600;
    font-size: 16px; */
    line-height: 1.8;
    align-items: center;
    border-bottom: 1px solid rgb(177, 177, 177);
    padding: 15px 10%;
    display: block;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    z-index: 100;
}

.NavItem {
    display: flex;
    justify-content: space-between;
}

.NavItem:hover {
    background-color: var(--dark-theme-color);
    color: var(--gold-theme-color)
}

.DropItem {
    background-color: #e6f8ff;
    padding: 10px 15%;
    border: none;
}

.DropItem:hover{
    background-color: var(--gold-theme-color);
    color: var(--dark-theme-color);
}

@media only screen and (max-width: 360px) {
    .NavItem {
        font-size: 15px;
    }
    
    .DropItem {
        font-size: 14px;
    }
}


