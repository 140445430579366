.Body {
    text-align: left;
    justify-content: left;
    line-height: 2.5;
    padding-top: 60px;
    padding-bottom: 60px;
    transition: all 0.7s;
}

.Card {
    border: none;
}

.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;  
    font-size: 30px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
}

.Col{
    margin-bottom: 20px;
}

/*
break
*/

.WhatsappIcon{
    margin-left: -5px;
}

.Icon {
    margin-top: 5px;
    text-align: end;
}



.AddressTitle{
    color: var(--dark-theme-color);
    font-size: 20px;
    /* margin-left: 36px; */
    font-weight: 700;
}

.AddressTitle1 {
    color: var(--dark-theme-color);
    font-size: 20px;
    /* margin-left: 47px; */
    font-weight: 700;
}

.AddressTitle2 {
    color: var(--dark-theme-color);
    font-size: 20px;
    /* margin-left: 39px; */
    font-weight: 700;
}

.CardBody1 {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.TelText {
    font-size: 23px;
}

.TelText2 {
    font-size: 23px;
}

.AddressText {
    font-size: 20px;
    margin-bottom: 0;
}

.AddressSpan {
    font-size: 15px;
    line-height: 1;
}

.TelText, .TelText2, .AddressText, .AddressSpan {
    color: var(--gold-theme-color);
    /* margin-left: 30px; */
}

@media only screen and (min-width: 992px) {
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (max-width: 991px) {
    
    .AddressTitle, .AddressTitle1, .AddressTitle2{
        font-size: 20px;
    }

    .TelText, .TelText2 {
        font-size: 23px;
    }

    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
        line-height: 2;
    }

    .Title{
        text-align: center;
        padding: 0;
    }
}

@media only screen and (max-width: 768px){
    .Icon {
        text-align: start;
    }
}

@media only screen and (max-width: 530px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        font-size: 20px;
    }
    

}

@media only screen and (max-width: 360px) {
    .Body{
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .TelText, .TelText2 {
        font-size: 20px;
    }

    .AddressText{
        font-size: 16px;
    }

    .AddressSpan {
        font-size: 13px;
    }
}

