.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;  
    font-size: 30px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
    text-align: left;
}

.Text{
    text-align: left;
    margin-left: 1rem;
    font-weight: bold;
}

.TH {
    min-width: 200px;
}

.TR {
    min-width: 50px;
}

.Disabled{
    pointer-events: none;
}