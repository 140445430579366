.BackDrop {
    background-image: url("@assets/background/top-banner-1.jpg");
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 150px;
    transition: all 1s;
}

@media only screen and (max-width: 700px) {
    .BackDrop {
        height: 125px;
    }
}

.Photo {
    background-image: url("@assets/background/school-logo-1-header.jpg");
    background-size: cover;
    height: 200px;
    background-position-x: center;
    background-position-y: top;
}


.Body {
    text-align: left;
    justify-content: left;
    line-height: 2.5;
    padding-top: 32px;
    padding-bottom: 100px;
    transition: all 0.7s;
    background-color: white;
    z-index: 2;
    background:url("@assets/background/school-bottom-1.jpg") no-repeat bottom left;
    background-size: auto 40rem;
}

.Card {
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
}

.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;  
    font-size: 30px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
}

.Paragraph {
    text-indent: 50px;
    text-align: justify;
}

.Col {
    padding: 0;
}

@media only screen and (min-width: 992px) {
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
        padding-bottom: 200px;
    }
}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (max-width: 991px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 200px;
    }

    .Title{
        text-align: center;
        padding: 0;
    }
}


@media only screen and (max-width: 500px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 300px;
    }

    .Title{
        font-size: 20px;
    }
}

@media only screen and (max-width: 360px) {
    .Body{
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 400px;
    }
}

