@font-face {
  font-family: 'TaipeiSans', 'Noto Sans HK', 'Noto Sans TC', 'Noto Serif TC', 微軟正黑體, sans-serif;
  src: url("./assets/font/TaipeiSans.ttf");
}

:root {
  --dark-theme-color: #272b6d;
  --light-theme-color: #4ac7f4;
  --gold-theme-color: #c8aa78;
  --grey-bg-color: rgb(185, 183, 183);
  --white-bg-color: white;
  --black-bg-color: black;
  --pink-bg-color: #337acc;
  --body-font: 'Roboto', 'Lato', 'Open Sans', 'TaipeiSans', 'Noto Sans HK', 'Noto Sans TC', 'Noto Serif TC', 微軟正黑體, sans-serif !important;
}

.TPSans {
  font-family: 'TaipeiSans', 'Noto Sans HK', 'Noto Sans TC', 'Noto Serif TC', 微軟正黑體, sans-serif;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin: 10px;
} */

/* font-family: 'Lato', sans-serif;
font-family: 'Noto Sans HK', sans-serif;
font-family: 'Noto Sans TC', sans-serif;
font-family: 'Noto Serif TC', serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif; */

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

body {
  margin: 0;
  font-family: 'Roboto', 'Lato', 'Open Sans', 'TaipeiSans', 'Noto Sans HK', 'Noto Sans TC', 'Noto Serif TC', 微軟正黑體, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white-bg-color);
}

.ZH {
  font-family: 'Noto Sans HK', 'Noto Sans TC', 'Noto Serif TC', 微軟正黑體, sans-serif;
}

.EN {
  font-family:'Roboto', 'Lato', 'Open Sans', sans-serif, 
}

