.BackDrop {
    background-image: url("../../../assets/background/top-banner-1.jpg");
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 150px;
    transition: all 1s;
}

@media only screen and (max-width: 700px) {
    .BackDrop {
        height: 125px;
    }
}

.Photo {
    background-image: url("@assets/background/school-logo-1-header.jpg");
    background-size: cover;
    height: 200px;
    background-position-x: center;
    background-position-y: top;
}

.Body {
    text-align: left;
    justify-content: left;
    line-height: 2.5;
    padding-top: 32px;
    padding-bottom: 200px;
    transition: all 0.7s;
    background-color: white;
    z-index: 2;
    background:url("../../../assets/background/school-bottom-1.jpg") no-repeat bottom left;
    background-size: auto 40rem;
}

.Card {
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
}

.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;  
    font-size: 30px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
}

.Teachers p {
    margin: 0;
}

.Teachers h4 {
    font-weight: 700;
}

.Teachers span {
    font-size: 20px;
    font-weight: 700;
    color: var(--gold-theme-color);
}


.Card2{
    border-left: 7px solid var(--light-theme-color);
    padding-left: 0px;
}

.Col{
    margin-bottom: 30px;
    padding: 0;
}

.Motto {
    color: var(--gold-theme-color);
    font-size: 30px;
    font-weight: 700;
}

.Card3{
    text-align: center;
    background-color: var(--dark-theme-color);
    border-radius: 60px;
}

@media only screen and (min-width: 992px) {
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
    }

    .Teachers p {
        font-size: 13px;
    }
    
    .Teachers span {
        font-size: 15px;
    }

    .Teachers h3 {
        font-size: 22px;
    }

}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }

    .Teachers p {
        font-size: 16px;
    }
    
    .Teachers span {
        font-size: 20px;
    }

    .Teachers h3 {
        font-size: 28px;
    }
}

@media only screen and (max-width: 991px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Motto {
        font-size: 30px;
    }

    .Title{
        text-align: center;
        padding: 0;
    }
}

@media only screen and (max-width: 500px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        font-size: 20px;
    }
}

@media only screen and (max-width: 360px) {
    .Body{
        padding-left: 20px;
        padding-right: 20px;
    }
}


