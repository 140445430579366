.Container {
    margin: 20px auto;
    
}

/* .Image {
    margin-bottom: 20px;
} */

.Image {
    border-bottom: 55px solid var(--dark-theme-color);
}

.SchoolLogo {
    margin-bottom: 20px;
    border-bottom: 20px solid var(--gold-theme-color);
}

.Icon {
    font-size: 70px;
}

.Card {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 20px auto 30px auto;
    border: none;
    color: var(--dark-theme-color);
}

.CardBody{
    font-size: 25px;
    font-weight: bold;
}

.Card:hover{
    cursor: pointer;
    color: var(--gold-theme-color);
}

.Body {
    transition: all 0.7s;
}

.Body hr{
    height: 0;
    background-color: var(--gold-theme-color);
    border: 2px solid var(--gold-theme-color);
}

.Body a {
    text-decoration: none;
}

.Dates{
    text-align: center;
}

.Card4{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 20px auto 30px auto;
    border: none;
    color: var(--dark-theme-color);
}

.Dates{
    margin-top: 10px;
}

.DateList {
    padding-top: 20px;
    line-height: 2;
    font-size: 18px;
    font-weight: bold;
    color: var(--gold-theme-color);
}

.DateList span {
    color: var(--dark-theme-color);
    font-weight: bolder;

}

.DateTitle {
    background-color: var(--dark-theme-color);
    color: var(--gold-theme-color);
    line-height: 3;
    font-weight: bold;
}

@media only screen and (min-width: 992px) {
    .IconTitle{
        font-size: 20px;
    }
    
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (max-width: 991px) {
    .Card {
        margin-bottom: 0px;
    }
    .IconTitle{
        font-size: 20px;
    }

    .Image {
        content:url(../../../assets/background/school-logo-3.jpg);
    }

}

@media only screen and (max-width: 600px) {
    .Card {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .IconTitle{
        font-size: 18px;
    }

    .Icon {
        width: 60px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 360px) {
    .Card {
        margin-bottom: 0px;
    }
    .IconTitle{
        font-size: 15px;
    }

    .Image {
        content:url(../../../assets/background/school-logo-2.jpg);
    }

    .Icon {
        width: 50px;
    }
}