.BackDrop {
    background-image: url("../../../assets/background/top-banner-2.jpg");
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 150px;
    transition: all 1s;
}

@media only screen and (max-width: 700px) {
    .BackDrop {
        height: 125px;
    }
}

.Image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 30px;
}

.Photo {
    background-image: url("@assets/background/school-logo-1-header.jpg");
    background-size: cover;
    height: 200px;
    background-position-x: center;
    background-position-y: top;
}
.Body {
    text-align: left;
    justify-content: left;
    line-height: 2.5;
    padding-top: 32px;
    padding-bottom: 400px;
    transition: all 0.7s;
    background-color: white;
    z-index: 2;
    background:url("../../../assets/background/school-bottom-3.jpg") no-repeat bottom right;
    background-size: auto 40rem;
}

.Card {
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
}

.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;   
    font-size: 30px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
}

.Title2{
    background-color: var(--dark-theme-color);
    padding-left: 40px;
    padding-right: 20px;
    font-weight: 600;   
    font-size: 25px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
    border-radius: 10px;
    line-height: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Toggle {
    text-decoration: none;
}

.Title2:hover{
    color: var(--light-theme-color);
}

.Title2:hover .Icon {
    color: var(--light-theme-color);
}

.Col{
    /* margin-bottom: 20px; */
    padding: 0;
}

.List3{
    columns: 2;
    list-style: square;
}
/*
break
*/

.TextTitle{
    color: var(--dark-theme-color);
    font-size: 20px;
    font-weight: 700;
    background-color: var(--light-theme-color);
    border-radius: 10px;
    padding-left: 40px;
    line-height: 2;
}
/* 
.Text {
    font-size: 14px;
} */

.List{
    list-style: disc;
}

.Text2 {
    color: var(--dark-theme-color);
    /* font-size: 18px; */
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
}

.Col2{
    margin: 0;
}

.CardBody2{
    padding-top: 0;
    padding-bottom: 0;
}

.List2 li{
    list-style: none;
    border-left: 10px solid var(--light-theme-color);
    margin-bottom: 10px;
    padding-left: 20px;
}

.List2 span{
    color: var(--dark-theme-color); 
    font-weight: 700;   
}
@media only screen and (min-width: 992px) {
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (max-width: 991px) {
    

    
    /* .TextTitle, .TextTitle1, .TextTitle2{
        font-size: 23px;
    } */

    /* .Text {
        font-size: 12px;
    } */

    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        text-align: center;
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    /* .TextTitle, .TextTitle1, .TextTitle2{
        font-size: 23px;
    } */

    .Image {
        width: 100%;
    }

    h1 span {
        display: block;
    }

    .Title2  {
        font-size: 18px;
        padding-left: 15px;
        padding-right: 0px;
    }

    .Title {
        padding-left: 16px;
    }

    .TextTitle {
        padding-left: 15px;
        font-size: 16px;
    }

    .DropDown {
        height: 30px;
    }
    
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        font-size: 20px;
    }
}


@media only screen and (max-width: 360px) {

    .List2 {
        padding-left: 0;
    }
    
    .List3{
        columns: 1;
        list-style: square;
    }    

    .Text {
        font-size: 14px;
    }

    .Body{
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 400px;
    }
}

