.Footer{
    background: var(--dark-theme-color);
    border-bottom: 20px solid var(--gold-theme-color);
    bottom: 0;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    padding: 60px 0px;
    position: relative;
    transition: all 0.7s;
    text-align: center;
}

.BracketText{
    margin-left: 5px;
    font-size: 70%;
}

.ColLeft{
    text-align: left;
    font-size: 15px;
    display: block;
    padding: 0;
}

.ColRight{
    text-align: right;
    font-size: 12px;
    padding: 0;
}

.Row{
    margin: 1.3rem 0rem;
    padding: 0;
}

.HR{
    margin-top: 30px;
    display: none;
}

.Icon{
    margin-right: 15px;
}

.TextBox{
    min-height: 30px;
    display: inline-flex;
    align-items: center;
}

.PText{
    margin: 0;
    padding: 0;
}

.Col{
    padding:0;
}

@media only screen and (max-width: 765px) {
    
    .HR{
        display: block;
    }

    .ColLeft{
        font-size:12px;
        padding:0;
        display: block;
    }

    .ColRight{
        text-align: left;
        font-size: 12px;
        padding:0;
    }
}

@media only screen and (max-width: 360px) {
    
    .PText{
        font-size: 11px;
        padding: 0;
    }

}