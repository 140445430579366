.BackDrop {
    background-image: url("../../../assets/background/top-banner-2.jpg");
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 150px;
    transition: all 1s;
}

@media only screen and (max-width: 700px) {
    .BackDrop {
        height: 125px;
    }
}

.Image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 30px;
}

.Photo {
    background-image: url("@assets/background/school-logo-1-header.jpg");
    background-size: cover;
    height: 200px;
    background-position-x: center;
    background-position-y: top;
}

.Body {
    text-align: left;
    justify-content: left;
    line-height: 2.5;
    padding-top: 32px;
    padding-bottom: 400px;
    transition: all 0.7s;
    background-color: white;
    z-index: 2;
    background:url("../../../assets/background/school-bottom-2.jpg") no-repeat bottom right;
    background-size: auto 40rem;
}

.Card {
    border: none;
    background-color: rgba(255, 255, 255, 0.7);
}

.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;   
    font-size: 35px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
}

.Col{
    /* margin-bottom: 20px; */
    padding: 0;
}

/*
break
*/

.TextTitle{
    color: var(--dark-theme-color);
    font-size: 20px;
    font-weight: 700;
    background-color: var(--light-theme-color);
    border-radius: 10px;
    padding-left: 20px;
    line-height: 2;
}



/* .Text {
    font-size: 14px;
} */

.List{
    list-style: disc;
}

.List3{
    columns: 2;
    list-style: square;
}

.Text2 {
    color: var(--dark-theme-color);
    /* font-size: 18px; */
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
}

.Col2{
    margin: 0;
    padding: 0;
}

.CardBody2{
    padding-top: 0;
    padding-bottom: 0;
}

.List2 li{
    list-style: none;
    border-left: 10px solid var(--light-theme-color);
    margin-bottom: 10px;
    padding-left: 20px;
}

.List2 span{
    color: var(--dark-theme-color); 
    font-weight: 700;   
}

.Grade {
    font-size: 25px;
}

@media only screen and (min-width: 992px) {
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (max-width: 991px) {
    
    /* .TextTitle, .TextTitle1, .TextTitle2{
        font-size: 23px;
    }

    .Text {
        font-size: 12px;
    } */

    .Photo {
        background-image: url("../../../assets/background/school-logo-3.jpg");
        max-height: 120px;
    }

    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        text-align: center;
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {

    .List2 {
        padding-left: 10;
    }

    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Grade {
        display: block;
        font-size: 16px;
    }

    .Title {
        line-height: 2;
        font-size: 25px;
    }

    .List3{
        columns: 1;
        list-style: square;
    }
}


@media only screen and (max-width: 360px) {

    .Photo {
        background-image: url("../../../assets/background/school-logo-2.jpg");
        max-height: 160px;
    }


    .Image {
        width: 100%;
    }
    
    .List2 {
        padding-left: 0;
    }

    .Text {
        font-size: 14px;
    }

    .Body{
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 200px;
    }
}

