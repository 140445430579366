.Body {
    text-align: left;
    justify-content: left;
    line-height: 2.5;
    padding-top: 60px;
    padding-bottom: 200px;
    transition: all 0.7s;
}

.Card {
    border: none;
}

.Title {
    border-left: 15px solid var(--dark-theme-color);
    padding-left: 40px;
    font-weight: 600;  
    font-size: 30px;
    line-height: 3;
    color: var(--gold-theme-color);
    margin: 0;
}

.Photo {
    background-image: url("@assets/background/school-logo-1-header.jpg");
    background-size: cover;
    height: 200px;
    background-position-x: center;
    background-position-y: top;
}

.Col{
    margin-bottom: 20px;
}

/*
break
*/

.TextTitle{
    color: var(--dark-theme-color);
    font-size: 25px;
    font-weight: 700;
}

.Text {
    font-size: 14px;
}

.Card span {
    color: var(--pink-bg-color);
    font-weight: 700;
}

@media only screen and (min-width: 992px) {
    .Body{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1450px) {
    .Body{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (max-width: 991px) {
    
    .TextTitle, .TextTitle1, .TextTitle2{
        font-size: 23px;
    }

    .Text {
        font-size: 14px;
    }

    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        text-align: center;
        padding: 0;
    }
}

@media only screen and (max-width: 500px) {
    .Body{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .Title{
        font-size: 20px;
    }
}

