.NavBar {
    align-items: center;
    background-color: white;
    display: flex;
    font-size: 1.2rem;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.NavItemsBox {
    align-items: center;
    display: flex;
    transition: all 0.5s;
}

.MenuIcon {
    color: var(--dark-theme-color);
    display: none;
}

.MenuIcon:hover {
    color: var(--gold-theme-color);
    cursor: pointer;
}

@media only screen and (min-width: 992px) {
    .NavBar{
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

@media only screen and (min-width: 1400px) {
    .NavBar{
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media only screen and (min-width: 1500px) {
    .NavBar{
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media only screen and (min-width: 1920px) {
    .NavBar{
        padding-left: 18rem;
        padding-right: 18rem;
    }
}

@media only screen and (max-width: 1600px) {
    .NavItemsBox {
        display: none;
    }

    .MenuIcon {
        display: block;
    }
    
}

@media only screen and (max-width: 991px) {
    .NavBar{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media only screen and (max-width: 360px) {
    .NavBar{
        padding-left: 20px;
        padding-right: 20px;
    }
}