body{
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-image: url("../../assets/images/portal-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* background-color: rgb(221, 221, 221); */
}

.ShowPW {
  font-family: 'Noto Sans HK', 'Noto Sans TC', 'Noto Serif TC', 微軟正黑體, sans-serif;
  font-size: 10px;
  font-weight: 300;
  color: grey;
  padding-left: 10px;
}

.CheckBoxContainer {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
}

.CheckBox {
  position: relative;
  top: 2px;
  border: none;
}

.Logo {
  height: 50px;
  margin-bottom: 3rem;
}

.ErrorMsg {
  text-align: center;
  margin-top: 20px;
}

.Msg {
  color: red;
  font-size: 12px;
  margin: 0px;
}

@media only screen and (max-width: 350px) {
  .Logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .CheckBoxContainer {
    justify-content: center;
  }
}