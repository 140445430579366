
.CloseBtn {
    color: var(--dark-theme-color);
    cursor: pointer;
}

.CloseBtn:hover {
    color: var(--gold-theme-color);
}

.Header {
    padding-top: 1rem;
    border-bottom: 1px solid rgb(177, 177, 177);
}

.MenuBody {
    padding: 0;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .MenuBody {
    scrollbar-width: auto;
    scrollbar-color: var(--light-theme-color) #e6e6e6;
  }

  /* Chrome, Edge, and Safari */
  .MenuBody::-webkit-scrollbar {
    width: 8px;
  }

  .MenuBody::-webkit-scrollbar-track {
    background: #e6e6e6;
  }

  .MenuBody::-webkit-scrollbar-thumb {
    background-color: var(--light-theme-color);
    border-radius: 5px;
    border: none;
  }

.NavItems, .DropItems {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
}

.NavButton {
    /* margin-top: 30px;*/
    margin-bottom: 20px;
    border: none;
    background-color: var(--gold-theme-color);
    color: var(--dark-theme-color);
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    width: 100%;
}

.NavButton:hover {
    background-color: var(--light-theme-color);
    color: var(--dark-theme-color);
    cursor: pointer;
}

.Container {
    /* padding-left: 10%;
    padding-right: 10%; */
    padding: 40px 10%;
}

.Portal {
  background-color: var(--dark-theme-color);
  color: white;
}

